import { render, staticRenderFns } from "./SideBar.vue?vue&type=template&id=64c18e78&scoped=true&"
import script from "./SideBar.vue?vue&type=script&lang=js&"
export * from "./SideBar.vue?vue&type=script&lang=js&"
import style0 from "./SideBar.vue?vue&type=style&index=0&id=64c18e78&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64c18e78",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ergonode/pim/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64c18e78')) {
      api.createRecord('64c18e78', component.options)
    } else {
      api.reload('64c18e78', component.options)
    }
    module.hot.accept("./SideBar.vue?vue&type=template&id=64c18e78&scoped=true&", function () {
      api.rerender('64c18e78', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/SideBar/SideBar.vue"
export default component.exports