var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "slider" }, [
    _c("div", {
      staticClass: "slider__sphere",
      style: _vm.spherePositionStyle,
      on: {
        mousedown: _vm.onInitResize,
        mouseenter: _vm.onMouseEnter,
        mouseleave: _vm.onMouseLeave
      }
    }),
    _vm._v(" "),
    _c("div", {
      ref: "tracker",
      staticClass: "slider__tracker",
      on: { click: _vm.onClick }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "slider__progress", style: _vm.progressStyle })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }