var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ExpandingList", {
    staticClass: "side-bar",
    attrs: {
      items: _vm.items,
      expanded: _vm.expanded,
      "render-ahead": 8,
      "option-key": _vm.optionKey
    },
    scopedSlots: _vm._u(
      [
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "SideBarStickyHeader",
                [
                  _vm._t("header", function() {
                    return [
                      _c("ListSearchHeader", {
                        attrs: {
                          title: _vm.title,
                          "search-value": _vm.searchValue,
                          searchable: _vm.searchable
                        },
                        on: { search: _vm.onSearch }
                      })
                    ]
                  })
                ],
                2
              )
            ]
          },
          proxy: true
        },
        {
          key: "body",
          fn: function() {
            return [
              _vm._t("body", function() {
                return [
                  _vm.isSearchPlaceholderVisible
                    ? _vm._t(
                        "noResultsPlaceholder",
                        function() {
                          return [
                            _c("SideBarNoResultsPlaceholder", {
                              on: { clear: _vm.onClearSearch }
                            })
                          ]
                        },
                        { onClearSearch: _vm.onClearSearch }
                      )
                    : _vm.isPlaceholderVisible
                    ? _vm._t("noDataPlaceholder", function() {
                        return [_c("SideBarNoDataPlaceholder")]
                      })
                    : _vm._e()
                ]
              })
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              _vm._t("footer", function() {
                return [_c("SideBarFooter")]
              })
            ]
          },
          proxy: true
        },
        {
          key: "item",
          fn: function(ref) {
            var item = ref.item
            var index = ref.index
            var onExpand = ref.onExpand
            return [
              _vm._t("item", null, {
                item: item,
                index: index,
                onExpand: onExpand
              })
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }