var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    { staticClass: "side-bar-sticky-header" },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }